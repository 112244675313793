<template>

  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <vuexy-logo />
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <div v-if="isLoading">
          <div class="align-items-center">
            <h4>{{ $t('Verifying company data ...') }}</h4>
            <b-spinner class="ml-auto" />
          </div>
        </div>

        <div>
          <h2
            v-if="!isLoading && isVerified"
            class="mb-3 mt"
          >
            {{ $t('Your company data verified!') }}
          </h2>
        </div>

        <b-img
          fluid
          :src="imgUrl"
        />
      </div>
    </div>
  </div>

</template>

<script>
/* eslint-disable global-require */
import { BImg, BLink, BSpinner } from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VuexyLogo,
    BLink,
    BImg,
    BSpinner,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/coming-soon.svg'),
      isVerified: false,
      isLoading: true,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/coming-soon-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  created() {
    this.$store.dispatch('user/verifyCompanyData', {
      id: this.$router.currentRoute.query.id,
      hash: this.$router.currentRoute.query.hash,
    })
      .then(() => {
        this.isLoading = false
        this.isVerified = true
      })
      .catch(error => {
        console.log(error)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error verifying company data. Please call our support'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
